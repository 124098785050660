#root {
    height: 100%;
    background-color: #fafdfe;
}

body > svg {
    display: none;
}


.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.displaced-dd-menu {
    transform: translate(0, -3px) !important;
    border-radius: 0 0 4px 4px;
    margin: 0 1px 0 0;
}

.displaced-dd-menu .ant-dropdown-menu-item {
    margin-bottom: 4px;
}

.ant-avatar-circle > i {
    margin-top: 6px !important;
}

.ant-input-affix-wrapper .ant-input {
    min-height: unset;
}

.ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
    width: 100%;
}

iframe {
    margin-top: 30px;
}